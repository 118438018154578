import {
  UPDATE_ADVERTISER,
  UPDATE_ADVERTISER_FAILED,
  UPDATE_ADVERTISER_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAILED,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_VENDOR,
  UPDATE_VENDOR_FAILED,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_EVP,
  UPDATE_EVP_FAILED,
  UPDATE_EVP_SUCCESS,
  UPDATE_SEVP,
  UPDATE_SEVP_FAILED,
  UPDATE_SEVP_SUCCESS,
  GET_MEMBERSHIP_REQUEST,
  GET_MEMBERSHIP_FAILED,
  GET_MEMBERSHIP_SUCCESS
} from '../actions'

const init = {
  loading: false,
  error: '',
  message: '',
  membershipDetails: null
}

const MembershipReducer = (state = init, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_ADVERTISER:
      return { ...state, loading: true }
    case UPDATE_ADVERTISER_SUCCESS:
      return { ...state, loading: false, error: '' }
    case UPDATE_ADVERTISER_FAILED:
      return { ...state, loading: false, message: '' }

    case UPDATE_CUSTOMER:
      return { ...state, loading: true }
    case UPDATE_CUSTOMER_SUCCESS:
      return { ...state, loading: false, error: '' }
    case UPDATE_CUSTOMER_FAILED:
      return { ...state, loading: false, message: '' }

    case UPDATE_VENDOR:
      return { ...state, loading: true }
    case UPDATE_VENDOR_SUCCESS:
      return { ...state, loading: false, error: '' }
    case UPDATE_VENDOR_FAILED:
      return { ...state, loading: false, message: '' }

    case UPDATE_EVP:
      return { ...state, loading: true }
    case UPDATE_EVP_SUCCESS:
      return { ...state, loading: false, error: '' }
    case UPDATE_EVP_FAILED:
      return { ...state, loading: false, message: '' }

    case UPDATE_SEVP:
      return { ...state, loading: true }
    case UPDATE_SEVP_SUCCESS:
      return { ...state, loading: false, error: '' }
    case UPDATE_SEVP_FAILED:
      return { ...state, loading: false, message: '' }

    case GET_MEMBERSHIP_REQUEST:
      return { ...state, loading: true }
    case GET_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        membershipDetails: payload.data,
        error: ''
      }
    case GET_MEMBERSHIP_FAILED:
      return { ...state, loading: false, message: '', error: payload }

    default:
      return { ...state }
  }
}

export default MembershipReducer
