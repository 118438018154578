// eslint-disable-next-line import/no-cycle
import {
  CREATE_ADVERT,
  CREATE_ADVERT_FAILED,
  CREATE_ADVERT_SUCCESS,
  EDIT_ADVERT,
  EDIT_ADVERT_FAILED,
  EDIT_ADVERT_SUCCESS,
  DELETE_ADVERT,
  DELETE_ADVERT_FAILED,
  DELETE_ADVERT_SUCCESS,
  RENEW_ADVERT,
  RENEW_ADVERT_FAILED,
  RENEW_ADVERT_SUCCESS,
  CREATE_FEATURE_ADVERT,
  CREATE_FEATURE_ADVERT_FAILED,
  CREATE_FEATURE_ADVERT_SUCCESS,
  GET_ADVERT,
  GET_ADVERT_FAILED,
  GET_ADVERT_SUCCESS,
  GET_USER_ADVERT,
  GET_USER_ADVERT_FAILED,
  GET_USER_ADVERT_SUCCESS,
  GET_FEATURE_ADVERT,
  GET_FEATURE_ADVERT_FAILED,
  GET_FEATURE_ADVERT_SUCCESS,
  CREATE_ADVERT_CATEGORY,
  CREATE_ADVERT_CATEGORY_FAILED,
  CREATE_ADVERT_CATEGORY_SUCCESS,
  EDIT_ADVERT_CATEGORY,
  EDIT_ADVERT_CATEGORY_FAILED,
  EDIT_ADVERT_CATEGORY_SUCCESS,
  DELETE_ADVERT_CATEGORY,
  DELETE_ADVERT_CATEGORY_FAILED,
  DELETE_ADVERT_CATEGORY_SUCCESS,
  GET_ADVERT_CATEGORY,
  GET_ADVERT_CATEGORY_FAILED,
  GET_ADVERT_CATEGORY_SUCCESS,
  GET_SINGLE_ADVERT,
  GET_SINGLE_ADVERT_FAILED,
  GET_SINGLE_ADVERT_SUCCESS,
  UPDATE_ADVERT_REVIEW,
  UPDATE_ADVERT_REVIEW_FAILED,
  UPDATE_ADVERT_REVIEW_SUCCESS,
} from '../actions';

const init = {
  loading: false,
  createAdvertLoading: false,
  editAdvertLoading: false,
  deleteAdvertLoading: false,
  renewAdvertLoading: false,
  error: false,
  createAdvertError: false,
  message: null,
  userAdverts: [],
  adverts: [],
  advertCategory: [],
  singleAdvert: null,
};

const AdvertReducer = (state = init, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_ADVERT:
      return { ...state, createAdvertLoading: true };
    case CREATE_ADVERT_SUCCESS:
      return {
        ...state,
        createAdvertLoading: false,
        message: payload,
        error: '',
      };
    case CREATE_ADVERT_FAILED:
      return {
        ...state,
        createAdvertLoading: false,
        message: '',
        createAdvertError: payload,
      };

    case EDIT_ADVERT:
      return { ...state, editAdvertLoading: true };
    case EDIT_ADVERT_SUCCESS:
      return {
        ...state,
        editAdvertLoading: false,
        message: payload,
        error: '',
      };
    case EDIT_ADVERT_FAILED:
      return {
        ...state,
        editAdvertLoading: false,
        message: '',
        error: payload,
      };

    case DELETE_ADVERT:
      return { ...state, loading: true };
    case DELETE_ADVERT_SUCCESS:
      return { ...state, loading: false, message: payload, error: '' };
    case DELETE_ADVERT_FAILED:
      return { ...state, loading: false, message: '', error: payload };

    case GET_ADVERT:
      return { ...state, loading: true };
    case GET_ADVERT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload,
        error: '',
        adverts: payload.data,
      };
    case GET_ADVERT_FAILED:
      return { ...state, loading: false, message: '', error: payload };
    case GET_FEATURE_ADVERT:
      return { ...state, loading: true };
    case GET_FEATURE_ADVERT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload,
        error: '',
        featureAdverts: payload.data,
      };
    case GET_FEATURE_ADVERT_FAILED:
      return { ...state, loading: false, message: '', error: payload };

    case GET_USER_ADVERT:
      return { ...state, loading: true };
    case GET_USER_ADVERT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload,
        error: '',
        userAdverts: payload.data,
      };
    case GET_USER_ADVERT_FAILED:
      return { ...state, loading: false, message: '', error: payload };

    case CREATE_ADVERT_CATEGORY:
      return { ...state, createAdvertLoading: true };
    case CREATE_ADVERT_CATEGORY_SUCCESS:
      return {
        ...state,
        createAdvertLoading: false,
        message: payload,
        error: '',
      };
    case CREATE_ADVERT_CATEGORY_FAILED:
      return {
        ...state,
        createAdvertLoading: false,
        message: '',
        error: payload,
      };

    case EDIT_ADVERT_CATEGORY:
      return { ...state, editAdvertLoading: true };
    case EDIT_ADVERT_CATEGORY_SUCCESS:
      return {
        ...state,
        editAdvertLoading: false,
        message: payload,
        error: '',
      };
    case EDIT_ADVERT_CATEGORY_FAILED:
      return {
        ...state,
        editAdvertLoading: false,
        message: '',
        error: payload,
      };

    case UPDATE_ADVERT_REVIEW:
      return { ...state, editAdvertLoading: true };
    case UPDATE_ADVERT_REVIEW_SUCCESS:
      return {
        ...state,
        editAdvertLoading: false,
        message: payload,
        error: '',
      };
    case UPDATE_ADVERT_REVIEW_FAILED:
      return {
        ...state,
        editAdvertLoading: false,
        message: '',
        error: payload,
      };

    case DELETE_ADVERT_CATEGORY:
      return { ...state, loading: true };
    case DELETE_ADVERT_CATEGORY_SUCCESS:
      return { ...state, loading: false, message: payload, error: '' };
    case DELETE_ADVERT_CATEGORY_FAILED:
      return { ...state, loading: false, message: '', error: payload };

    case RENEW_ADVERT:
      return { ...state, loading: true };
    case RENEW_ADVERT_SUCCESS:
      return { ...state, loading: false, message: payload, error: '' };
    case RENEW_ADVERT_FAILED:
      return { ...state, loading: false, message: '', error: payload };

    case CREATE_FEATURE_ADVERT:
      return { ...state, loading: true };
    case CREATE_FEATURE_ADVERT_SUCCESS:
      return { ...state, loading: false, message: payload, error: '' };
    case CREATE_FEATURE_ADVERT_FAILED:
      return { ...state, loading: false, message: '', error: payload };

    case GET_ADVERT_CATEGORY:
      return { ...state, loading: true };
    case GET_ADVERT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload,
        error: '',
        advertCategory: payload.data,
      };
    case GET_ADVERT_CATEGORY_FAILED:
      return { ...state, loading: false, message: '', error: payload };

    case GET_SINGLE_ADVERT:
      return { ...state, loading: true };
    case GET_SINGLE_ADVERT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload,
        error: '',
        singleAdvert: payload.data,
      };
    case GET_SINGLE_ADVERT_FAILED:
      return { ...state, loading: false, message: '', error: payload };

    default:
      return { ...state };
  }
};

export default AdvertReducer;
