// eslint-disable-next-line import/no-cycle
import { all, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'helpers/axios'
import { getCurrentUser, setLastURL } from 'helpers/Utils'
import { NotificationManager } from 'components/common/react-notifications'

import {
  UPDATE_ADVERTISER,
  UPDATE_CUSTOMER,
  UPDATE_VENDOR,
  UPDATE_EVP,
  UPDATE_SEVP,
  GET_MEMBERSHIP_REQUEST,
  // initiatePayment,
  updateAdvertiserSuccess,
  updateAdvertiserFailed,
  updateCustomerSuccess,
  updateCustomerFailed,
  updateSkilledSuccess,
  updateSkilledFailed,
  updateEVPSuccess,
  updateEVPFailed,
  updateSEVPSuccess,
  updateSEVPFailed,
  getMembershipFailed,
  getMembershipSuccess
} from '../actions'

function * updateAdvertiser (payload) {
  // const config = {
  //     membershipId: payload.payload.data.membershipId,
  //     email: payload.payload.data.email,
  //     redirect_url: `http://${window.location.host}/app/membership-payment/${payload.payload.data.membershipId}`,
  //     payment_options: 'card,mobilemoney,ussd',
  //     currency: 'NGN'

  // }
  const URL = window.location.href
  setLastURL(URL)
  const currentUser = getCurrentUser()
  const { phoneNumber } = currentUser
  //    yield console.log({...payload.payload.data, phoneNumber})
  try {
    const response = yield axios.post('profile/advertiser', {
      ...payload.payload.data,
      phoneNumber
    })
    if (response.data.success) {
      yield put(updateAdvertiserSuccess(response.data.message))
      NotificationManager.success(
        response.data.message,
        response.data.message,
        3000,
        null,
        null,
        ''
      )
      window.location.reload();

      // yield put(initiatePayment(config))´
    } else {
      yield put(updateAdvertiserFailed(response.data.message))
    //   NotificationManager.warning(
    //     response.data.message,
    //     'Error',
    //     3000,
    //     null,
    //     null,
    //     ''
    //   )
    }
  } catch (error) {
    console.log(error.response)
    let message
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = 'Not found'
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
    } else if (error.message) {
      message = error.message
    }
    yield put(updateAdvertiserFailed(message))
    NotificationManager.warning(message, 'Error', 3000, null, null, '')
  }
}

function * updateCustomer (payload) {
  // const config = {
  //     membershipId: payload.payload.data.membershipId,
  //     email: payload.payload.data.email,
  //     redirect_url: `http://${window.location.host}/app/membership-payment/${payload.payload.data.membershipId}`,
  //     payment_options: 'card,mobilemoney,ussd',
  //     currency: 'NGN'

  // }
  const URL = window.location.href
  setLastURL(URL)
  const currentUser = getCurrentUser()
  const { phoneNumber } = currentUser
  //    yield console.log({...payload.payload.data, phoneNumber})
  try {
    const response = yield axios.post('profile/customer', {
      ...payload.payload.data,
      phoneNumber
    })
    console.log(response)
    if (response.data.success) {
      yield put(updateCustomerSuccess(response.data.message))
      NotificationManager.success(
        response.data.message,
        response.data.message,
        3000,
        null,
        null,
        ''
      )
      window.location.reload();
      // yield put(initiatePayment(config))
    } else {
      yield put(updateCustomerFailed(response.data.message))
    //   NotificationManager.warning(
    //     response.data.message,
    //     'Error',
    //     3000,
    //     null,
    //     null,
    //     ''
    //   )
    }
  } catch (error) {
    console.log(error.response)
    let message
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = 'Not found'
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
    } else if (error.message) {
      message = error.message
    }
    yield put(updateCustomerFailed(message))
    NotificationManager.warning(message, 'Error', 3000, null, null, '')
  }
}

function * updateSkilled (payload) {
  console.log(payload)
  // const config = {
  //     membershipId: payload.payload.data.membershipId,
  //     email: payload.payload.data.email,
  //     redirect_url: `http://${window.location.host}/app/membership-payment/${payload.payload.data.membershipId}`,
  //     payment_options: 'card,mobilemoney,ussd',
  //     currency: 'NGN'

  // }
  const URL = window.location.href
  setLastURL(URL)
  const currentUser = getCurrentUser()
  const { phoneNumber } = currentUser
  yield console.log({ ...payload.payload.data, phoneNumber })
  try {
    const response = yield axios.post('profile/skilled', {
      ...payload.payload.data,
      phoneNumber
    })
    if (response.data.success) {
      yield put(updateSkilledSuccess(response.data.message))
      NotificationManager.success(
        response.data.message,
        response.data.message,
        3000,
        null,
        null,
        ''
      )
      window.location.reload();
      // yield put(initiatePayment(config))
    } else {
      yield put(updateSkilledFailed(response.data.message))
    //   NotificationManager.warning(
    //     response.data.message,
    //     'Error',
    //     3000,
    //     null,
    //     null,
    //     ''
    //   )
    }
  } catch (error) {
    console.log(error.response)
    let message
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = 'Not found'
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
    } else if (error.message) {
      message = error.message
    }
    yield put(updateSkilledFailed(message))
    NotificationManager.warning(message, 'Error', 3000, null, null, '')
  }
}

function * updateEVP (payload) {
  // const config = {
  //     membershipId: payload.payload.data.membershipId,
  //     email: payload.payload.data.email,
  //     redirect_url: `http://${window.location.host}/app/membership-payment/${payload.payload.data.membershipId}`,
  //     payment_options: 'card,mobilemoney,ussd',
  //     currency: 'NGN'

  // }
  const URL = window.location.href
  setLastURL(URL)
  const currentUser = getCurrentUser()
  const { phoneNumber } = currentUser
  //    yield console.log({...payload.payload.data, phoneNumber})
  try {
    const response = yield axios.post('profile/evp', {
      ...payload.payload.data,
      phoneNumber
    })
    if (response.data.success) {
      yield put(updateEVPSuccess(response.data.message))
      NotificationManager.success(
        response.data.message,
        response.data.message,
        3000,
        null,
        null,
        ''
      )
      window.location.reload();
      // yield put(initiatePayment(config))
    } else {
      yield put(updateEVPFailed(response.data.message))
    //   NotificationManager.warning(
    //     response.data.message,
    //     'Error',
    //     3000,
    //     null,
    //     null,
    //     ''
    //   )
    }
  } catch (error) {
    console.log(error.response)
    let message
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = 'Not found'
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
    } else if (error.message) {
      message = error.message
    }
    yield put(updateEVPFailed(message))
    NotificationManager.warning(message, 'Error', 3000, null, null, '')
  }
}

function * updateSEVP (payload) {
  // const config = {
  //     membershipId: payload.payload.data.membershipId,
  //     email: payload.payload.data.email,
  //     redirect_url: `http://${window.location.host}/app/membership-payment/${payload.payload.data.membershipId}`,
  //     payment_options: 'card,mobilemoney,ussd',
  //     currency: 'NGN'

  // }
  const URL = window.location.href
  setLastURL(URL)
  const currentUser = getCurrentUser()
  const { phoneNumber } = currentUser
  yield console.log(payload.payload.data)

  try {
    const response = yield axios.post('/profile/superevp', {
      ...payload.payload.data,
      phoneNumber
    })
    if (response.data.success) {
      yield put(updateSEVPSuccess(response.data.message))
      NotificationManager.success(
        response.data.message,
        response.data.message,
        3000,
        null,
        null,
        ''
      )
      window.location.reload();
      // yield put(initiatePayment(config))
    } else {
      yield put(updateSEVPFailed(response.data.message))
    //   NotificationManager.warning(
    //     response.data.message,
    //     'Error',
    //     3000,
    //     null,
    //     null,
    //     ''
    //   )
    }
  } catch (error) {
    console.log(error.response)
    let message
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = 'Not found'
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
    } else if (error.message) {
      message = error.message
    }
    yield put(updateSEVPFailed(message))
    NotificationManager.warning(message, 'Error', 3000, null, null, '')
  }
}

function * getMembership () {
  try {
    const response = yield axios.get('membership/all-memberships')
    if (response.data.success) {
      yield put(getMembershipSuccess(response.data.data))
      console.log(response)
    } else {
      yield put(getMembershipFailed(response.data.message))
    }
  } catch (error) {
    console.log(error)
    let message
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error'
          break
        case 404:
          message = 'Not found'
          break
        case 401:
          message = 'Invalid credentials'
          break
        default:
          message = error.response.data.message
      }
    } else if (error.message) {
      message = error.message
    }
    yield put(getMembershipFailed(message))
  }
}

export function * watchUpdateAdvertiser () {
  yield takeEvery(UPDATE_ADVERTISER, updateAdvertiser)
}
export function * watchUpdateCustomer () {
  yield takeEvery(UPDATE_CUSTOMER, updateCustomer)
}
export function * watchUpdateSkilled () {
  yield takeEvery(UPDATE_VENDOR, updateSkilled)
}
export function * watchUpdateEVP () {
  yield takeEvery(UPDATE_EVP, updateEVP)
}
export function * watchUpdateSEVP () {
  yield takeEvery(UPDATE_SEVP, updateSEVP)
}
export function * watchGetMembership () {
  yield takeEvery(GET_MEMBERSHIP_REQUEST, getMembership)
}
export default function * rootSaga () {
  yield all([
    fork(watchUpdateAdvertiser),
    fork(watchUpdateCustomer),
    fork(watchUpdateSkilled),
    fork(watchUpdateEVP),
    fork(watchUpdateSEVP),
    fork(watchGetMembership)
  ])
}
