// eslint-disable-next-line import/no-cycle
import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import axios from 'helpers/axios';
import { setUser } from 'helpers/Utils';

import {
  GET_USER,
  GET_MY_USERS,
  GET_SINGLE_USER,
  GET_USER_PROFILE,
  EVP_SETTINGS_REQUEST,
  SEVP_SETTINGS_REQUEST,
  VENDOR_SETTINGS_REQUEST,
  CUSTOMER_SETTINGS_REQUEST,
  ADVERTISER_SETTINGS_REQUEST,
  ADVERTS_SETTINGS_REQUEST,
  GET_SETTINGS_REQUEST,
  CREATE_USER_REQUEST,
  VERIFY_USER_REQUEST,
  CREATE_ADVERTISER,
  CREATE_CUSTOMER,
  CREATE_VENDOR,
  CREATE_EVP,
  CREATE_SUPEREVP,
  SUSPEND_MY_USER,
  UNSUSPEND_MY_USER,
  FUND_MY_USER,
  WITHDRAW_USER_FUND,
  BLOCK_USER,
  UNBLOCK_USER,
  ADD_CREDIT,
  UPDATE_USER_PROFILE,
  UPDATE_PROFILEPIC,
  CREATE_USER_CATEGORY,
  EDIT_USER_CATEGORY,
  DELETE_USER_CATEGORY,
  GET_USER_CATEGORY,
  DELETE_USER,
  // UPDATE_USER_PROFILE,
  CREATE_USER_SKILL,
  EDIT_USER_SKILL,
  DELETE_USER_SKILL,
  CONFIRM_USER,
  EDIT_USER,
  createAdvertiserSuccess,
  createAdvertiserFailed,
  createCustomerSuccess,
  createCustomerFailed,
  createSkilledSuccess,
  createSkilledFailed,
  createEVPSuccess,
  createEVPFailed,
  createSuperEVPSuccess,
  createSuperEVPFailed,
  getUserSuccess,
  getUserFailed,
  getSingleUserSuccess,
  getSingleUserFailed,
  getUserProfileSuccess,
  getUserProfileFailed,
  saveEVPSettingsFailed,
  saveEVPSettingsSuccess,
  saveSEVPSettingsFailed,
  saveSEVPSettingsSuccess,
  saveSkilledSettingsFailed,
  saveSkilledSettingsSuccess,
  saveCustomerSettingsFailed,
  saveCustomerSettingsSuccess,
  saveAdvertiserSettingsFailed,
  saveAdvertiserSettingsSuccess,
  saveAdvertsSettingsFailed,
  saveAdvertsSettingsSuccess,
  getSettingsFailed,
  getSettingsSuccess,
  // createUserSuccess,
  // createUserFailed,
  verifyUserSuccess,
  verifyUserFailed,
  getMyUsersSuccess,
  getMyUsersFailed,
  suspendMyUserFailed,
  suspendMyUserSuccess,
  unSuspendMyUserSuccess,
  unSuspendMyUserFailed,
  fundMyUserSuccess,
  fundMyUserFailed,
  withdrawUserFundSuccess,
  withdrawUserFundFailed,
  blockUserFailed,
  blockUserSuccess,
  unblockUserSuccess,
  unblockUserFailed,
  addCreditSuccess,
  addCreditFailed,
  updateProfileSuccess,
  updateProfileFailed,
  logoutUser,
  createUserCategorySuccess,
  createUserCategoryFailed,
  updateUserCategorySuccess,
  updateUserCategoryFailed,
  deleteUserCategorySuccess,
  deleteUserCategoryFailed,
  getUserCategorySucces,
  getUserCategoryFailed,
  createUserSkillSuccess,
  createUserSkillFailed,
  updateUserSkillSuccess,
  updateUserSkillFailed,
  deleteUserSkillSuccess,
  deleteUserSkillFailed,
  editUserSuccess,
  editUserFailed,
  deleteUserSuccess,
  deleteUserFailed,
  confirmUserFailed,
  confirmUserSuccess,
  updateProfilePicSuccess,
  updateProfilePicFailed,
} from '../actions';

function* getUser() {
  try {
    const response = yield axios.get(`analytics/all-users`);
    // console.log(response.data)
    if (response.data.success) {
      yield put(getUserSuccess(response.data));
      // window.location.reload();
    } else {
      yield put(getUserFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getUserFailed(message));
  }
}

function* getSingleUser(payload) {
  // yield console.log(payload.payload.data);
  try {
    const response = yield axios.get(
      `/user-management/single-user?userId=${payload.payload.id}`
    );
    console.log(response.data);
    if (response.data.success) {
      yield put(getSingleUserSuccess(response.data));
      // window.location.reload();
    } else {
      yield put(getSingleUserFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getSingleUserFailed(message));
  }
}

function* getUserProfile() {
  try {
    const response = yield axios.get(`/user/view-profile`);
    console.log(response.data);
    if (response.data.success) {
      yield put(getUserProfileSuccess(response.data));
      // window.location.reload();
    } else {
      yield put(getUserProfileFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    console.log(error);
    // window.location.href = '/auth/login';

    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          yield put(logoutUser());
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getUserProfileFailed(message));
  }
}

function* saveEVPSettings(payload) {
  // yield console.log(payload.payload.formData)
  const { membershipId, categories } = payload.payload.formData;
  // console.log(categories)
  try {
    const response = yield axios.patch(
      `/settings/evp?membershipId=${membershipId}`,
      { categories }
    );
    // console.log(response.data)
    if (response.data.success) {
      yield put(saveEVPSettingsSuccess(response.data));
      window.location.reload();
    } else {
      yield put(saveEVPSettingsFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(saveEVPSettingsFailed(message));
  }
}

function* saveSEVPSettings(payload) {
  const { membershipId, superEVPAmount, superEVPProfit } =
    payload.payload.formData;
  // console.log(superEVPAmount)
  const data = {
    amount: superEVPAmount,
    percentageProfit: superEVPProfit,
  };
  // yield console.log(data)

  try {
    const response = yield axios.patch(
      `/settings/superevp?membershipId=${membershipId}`,
      data
    );
    // console.log(response.data)
    if (response.data.success) {
      yield put(saveSEVPSettingsSuccess(response.data));
      window.location.reload();
    } else {
      yield put(saveSEVPSettingsFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(saveSEVPSettingsFailed(message));
  }
}

function* saveSkilledSettings(payload) {
  // yield console.log(payload.payload.formData)
  const { membershipId, skilledAmount, skilledDuration } =
    payload.payload.formData;

  try {
    const response = yield axios.patch(
      `settings/skilled?membershipId=${membershipId}`,
      {
        amount: skilledAmount,
        duration: skilledDuration,
      }
    );
    // console.log(response.data)
    if (response.data.success) {
      yield put(saveSkilledSettingsSuccess(response.data));
      // window.location.reload();
    } else {
      yield put(saveSkilledSettingsFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(saveSkilledSettingsFailed(message));
  }
}

function* saveCustomerSettings(payload) {
  // yield console.log(payload.payload.formData)
  const { membershipId, customerAmount, customerDuration } =
    payload.payload.formData;

  try {
    const response = yield axios.patch(
      `settings/customer?membershipId=${membershipId}`,
      {
        amount: customerAmount,
        duration: customerDuration,
      }
    );
    // console.log(response.data)
    if (response.data.success) {
      yield put(saveCustomerSettingsSuccess(response.data));
      window.location.reload();
    } else {
      yield put(saveCustomerSettingsFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(saveCustomerSettingsFailed(message));
  }
}

function* saveAdvertiserSettings(payload) {
  // yield console.log(payload.payload.formData)
  const { membershipId, advertiserAmount, advertiserDuration } =
    payload.payload.formData;

  try {
    const response = yield axios.patch(
      `settings/advertiser?membershipId=${membershipId}`,
      {
        amount: advertiserAmount,
        duration: advertiserDuration,
      }
    );
    // console.log(response.data)
    if (response.data.success) {
      yield put(saveAdvertiserSettingsSuccess(response.data));
      window.location.reload();
    } else {
      yield put(saveAdvertiserSettingsFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(saveAdvertiserSettingsFailed(message));
  }
}

function* saveAdvertsSettings(payload) {
  // // yield console.log(payload.payload.formData)
  // const { advertAmount } = payload.payload.formData;
  try {
    const response = yield axios.put(`/settings/advert-amount`, {
      amount: payload.payload.formData,
    });
    // console.log(response.data)
    if (response.data.success) {
      yield put(saveAdvertsSettingsSuccess(response.data));
      window.location.reload();
    } else {
      yield put(saveAdvertsSettingsFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(saveAdvertsSettingsFailed(message));
  }
}

function* getSettings() {
  try {
    const response = yield axios.get(`/settings/all`);
    // console.log(response.data)
    if (response.data.success) {
      yield put(getSettingsSuccess(response.data));
    } else {
      yield put(getSettingsFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getSettingsFailed(message));
  }
}

function* verifyUser(payload) {
  yield console.log(payload.payload.data);
  try {
    const response = yield axios.post('/auth/verify-token', {
      token: payload.payload.data,
    });
    // console.log(response.data)
    if (response.data.success) {
      yield put(verifyUserSuccess(response.data));
      // window.location.reload();
    } else {
      yield put(verifyUserFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(verifyUserFailed(message));
  }
}

function* createAdvertiser(payload) {
  console.log(payload.payload.data);
  //    yield console.log({...payload.payload.data, phoneNumber})
  try {
    const response = yield axios.post(
      '/profile/evp-superevp/advertiser',
      payload.payload.data
    );
    if (response.data.success) {
      yield put(createAdvertiserSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(createAdvertiserFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = error.response.data.message;
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createAdvertiserFailed(message));
  }
}

function* createCustomer(payload) {
  //    yield console.log({...payload.payload.data, phoneNumber})
  try {
    const response = yield axios.post(
      '/profile/evp-superevp/customer',
      payload.payload.data
    );
    console.log(response);
    if (response.data.success) {
      yield put(createCustomerSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(createCustomerFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createCustomerFailed(message));
  }
}

function* createSkilled(payload) {
  yield console.log(payload.payload.data);
  try {
    const response = yield axios.post(
      '/profile/evp-superevp/skilled',
      payload.payload.data
    );
    if (response.data.success) {
      yield put(createSkilledSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(createSkilledFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createSkilledFailed(message));
  }
}

function* createEVP(payload) {
  //    yield console.log({...payload.payload.data, phoneNumber})
  try {
    const response = yield axios.post(
      '/profile/evp-superevp/evp',
      payload.payload.data
    );
    if (response.data.success) {
      yield put(createEVPSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(createEVPFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createEVPFailed(message));
  }
}

function* createSuperEVP(payload) {
  yield console.log(payload.payload.data);

  try {
    const response = yield axios.post(
      '/profile/evp-superevp/superevp',
      payload.payload.data
    );
    if (response.data.success) {
      yield put(createSuperEVPSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(createSuperEVPFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createSuperEVPFailed(message));
  }
}

function* getMyUsers() {
  try {
    const response = yield axios.get(`/auth/evp-superevp/my-users`);
    // console.log(response.data)
    if (response.data.success) {
      yield put(getMyUsersSuccess(response.data));
      // window.location.reload();
    } else {
      yield put(getMyUsersFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getMyUsersFailed(message));
  }
}

function* suspendMyUser(payload) {
  //    yield console.log({...payload.payload.data, phoneNumber})
  const data = {
    userId: payload.payload,
  };
  console.log(data);

  try {
    const response = yield axios.get(
      `/profile/evp-superevp/retrieve-account?userId=${payload.payload}`,
      data
    );
    console.log(response);
    if (response.data.success) {
      yield put(suspendMyUserSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(suspendMyUserFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(suspendMyUserFailed(message));
  }
}

function* unSuspendMyUser(payload) {
  //    yield console.log({...payload.payload.data, phoneNumber})
  try {
    const response = yield axios.post(
      '/profile/evp-superevp/customer',
      payload.payload.id
    );
    console.log(response);
    if (response.data.success) {
      yield put(unSuspendMyUserSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(unSuspendMyUserFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(unSuspendMyUserFailed(message));
  }
}

function* withdrawUserFund(payload) {
  //    yield console.log({...payload.payload.data, phoneNumber})
  // console.log(payload.payload.formData)
  try {
    const response = yield axios.put(
      '/profile/evp-superevp/withdraw-fund',
      payload.payload.formData
    );
    console.log(response);
    if (response.data.success) {
      yield put(withdrawUserFundSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(withdrawUserFundFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(withdrawUserFundFailed(message));
  }
}

function* fundMyUser(payload) {
  try {
    const response = yield axios.put('/profile/evp-superevp/fund-user', {
      ...payload.payload.formData,
    });
    console.log(response);
    if (response.data.success) {
      yield put(fundMyUserSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(fundMyUserFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(fundMyUserFailed(message));
  }
}

function* blockUser(payload) {
  //    yield console.log({...payload.payload.data, phoneNumber})
  // const data = {
  //     userId: payload.payload
  // }
  console.log(payload.payload);

  try {
    const response = yield axios.get(
      `/user-management/block-user?userId=${payload.payload}`
    );
    console.log(response);
    if (response.data.success) {
      yield put(blockUserSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(blockUserFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(blockUserFailed(message));
  }
}

function* unblockUser(payload) {
  //    yield console.log({...payload.payload.data, phoneNumber})
  try {
    const response = yield axios.get(
      `/user-management/unblock-user?userId=${payload.payload}`
    );
    console.log(response);
    if (response.data.success) {
      yield put(unblockUserSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(unblockUserFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(unblockUserFailed(message));
  }
}

function* addCredit(payload) {
  // console.log(payload.payload.formData);
  const { userId, amount } = payload.payload.formData;
  console.log(userId);

  try {
    const response = yield axios.post(
      `/user-management/add-credit?userId=${userId}`,
      {
        credit: amount,
      }
    );
    // console.log(response)
    if (response.data.success) {
      yield put(addCreditSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(addCreditFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(addCreditFailed(message));
  }
}

function* updateProfile(payload) {
  console.log(payload);
  try {
    const response = yield axios.patch(
      `/user/edit-profile`,
      payload.payload.formData
    );
    console.log(response);
    if (response.data.success) {
      setUser(response.data.data);
      yield put(updateProfileSuccess(response.data.message));
      console.log(response.data);
      // window.location.reload();
    } else {
      yield put(updateProfileFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(updateProfileFailed(message));
  }
}

function* updateProfilePic(payload) {
  console.log(payload);
  const profilePic = payload.payload.formData;

  try {
    const response = yield axios.patch(`/user/upload-profile-picture`, {
      profilePic,
    });
    // console.log(response)
    if (response.data.success) {
      setUser(response.data.data);
      yield put(updateProfilePicSuccess(response.data.message));
      console.log(response.data);
      window.location.reload();
    } else {
      yield put(updateProfilePicFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(updateProfilePicFailed(message));
  }
}

function* getUserCategory() {
  try {
    const response = yield axios.get(`/user-management/all-user-categories`);
    console.log(response.data);
    if (response.data.success) {
      yield put(getUserCategorySucces(response.data));
    } else {
      yield put(getUserCategoryFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getUserCategoryFailed(message));
  }
}

function* createUserCategory(payload) {
  //    yield console.log(payload.payload.data)
  try {
    const response = yield axios.post(
      '/user-management/create-user-category',
      payload.payload.data
    );
    if (response.data.success) {
      yield put(createUserCategorySuccess(response.data.message));
      // yield call(getAdvert);
      window.location.reload();
      yield call(getUserCategory);
      // yield call(getUserProfile)
    } else {
      yield put(createUserCategoryFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createUserCategoryFailed(message));
  }
}

function* updateUserCategory(payload) {
  yield console.log(payload);
  const { id, message } = payload.payload;
  try {
    const response = yield axios.put(
      `/user-management/edit-user-category?catId=${id}`,
      { name: message }
    );
    console.log(response);
    if (response.data.success) {
      yield put(updateUserCategorySuccess(response.data.message));
      yield call(getUserCategory);
      window.location.reload();
    } else {
      yield put(updateUserCategoryFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    console.log(error);

    let errMessage;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          errMessage = 'Internal Server Error';
          break;
        case 404:
          errMessage = 'Not found';
          break;
        case 401:
          errMessage = 'Invalid credentials';
          break;
        default:
          errMessage = error.response.data.message;
      }
    } else if (error.message) {
      errMessage = error.message;
    }
    yield put(updateUserCategoryFailed(errMessage));
  }
}

function* deleteUserCategory(payload) {
  // yield console.log('.........');
  // yield console.log(payload.payload.id)
  try {
    const response = yield axios.delete(
      `/user-management/delete-user-category?catId=${payload.payload.id}`
    );
    if (response.data.success) {
      yield put(deleteUserCategorySuccess(response.data.message));
      window.location.reload();
      // yield call(getUserCategory);
    } else {
      yield put(deleteUserCategoryFailed(response.data.message));
    }
  } catch (error) {
    console.log(error);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteUserCategoryFailed(message));
  }
}

function* createUserSkill(payload) {
  yield console.log(payload);
  const { name, id, skills } = payload.payload.data.name;
  // console.log(name, id, skills);

  try {
    const response = yield axios.put(
      `/user-management/edit-user-category?catId=${id}`,
      {
        name,
        skills,
      }
    );
    if (response.data.success) {
      yield put(createUserSkillSuccess(response.data.message));
      window.location.reload();
      // yield call(getAdvert);
      // yield call(getUserSkill);
      // yield call(getUserProfile)
    } else {
      yield put(createUserSkillFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createUserSkillFailed(message));
  }
}

function* updateUserSkill(payload) {
  // yield console.log(payload);

  const { id, name, skills } = payload.payload.message;
  try {
    const response = yield axios.put(
      `/user-management/edit-user-category?catId=${id}`,
      {
        name,
        skills,
      }
    );
    if (response.data.success) {
      yield put(updateUserSkillSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(updateUserSkillFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let errMessage;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          errMessage = 'Internal Server Error';
          break;
        case 404:
          errMessage = 'Not found';
          break;
        case 401:
          errMessage = 'Invalid credentials';
          break;
        default:
          errMessage = error.response.data.message;
      }
    } else if (error.message) {
      errMessage = error.message;
    }
    yield put(updateUserSkillFailed(errMessage));
  }
}

function* deleteUserSkill(payload) {
  yield console.log(payload);
  const { id, name, skills } = payload.payload.id;
  // console.log(id, name, skills);
  try {
    const response = yield axios.put(
      `/user-management/edit-user-category?catId=${id}`,
      {
        name,
        skills,
      }
    );
    if (response.data.success) {
      yield put(deleteUserSkillSuccess(response.data.message));
      window.location.reload();
    } else {
      yield put(deleteUserSkillFailed(response.data.message));
    }
  } catch (error) {
    console.log(error);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteUserSkillFailed(message));
  }
}

function* editUser(payload) {
  yield console.log(payload);
  const { address, country, lga, name, state, userId, email } =
    payload.payload.data;

  try {
    const response = yield axios.put(
      `/user-management/edit-user?userId=${userId}`,
      {
        address,
        country,
        lga,
        email,
        fullName: name,
        state,
        userId,
      }
    );
    console.log(response);
    if (response.data.success) {
      yield put(editUserSuccess(response.data.message));
    } else {
      yield put(editUserFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let errMessage;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          errMessage = 'Internal Server Error';
          break;
        case 404:
          errMessage = 'Not found';
          break;
        case 401:
          errMessage = 'Invalid credentials';
          break;
        default:
          errMessage = error.response.data.message;
      }
    } else if (error.message) {
      errMessage = error.message;
    }
    yield put(editUserFailed(errMessage));
  }
}

function* deleteUser(payload) {
  yield console.log(payload);
  const userId = payload.payload.data;
  yield console.log(userId);

  try {
    const response = yield axios.delete(
      `/user-management/delete-user?userId=${userId}`
    );
    if (response.data.success) {
      yield put(deleteUserSuccess(response.data.message));
      window.location.href = '/app/users';
    } else {
      yield put(deleteUserFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let errMessage;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          errMessage = 'Internal Server Error';
          break;
        case 404:
          errMessage = 'Not found';
          break;
        case 401:
          errMessage = 'Invalid credentials';
          break;
        default:
          errMessage = error.response.data.message;
      }
    } else if (error.message) {
      errMessage = error.message;
    }
    yield put(deleteUserFailed(errMessage));
  }
}

function* createUser(payload) {
  yield console.log(payload.payload.data);

  // try {
  //     const response = yield axios.post(`/auth/evp-superevp/register`, payload.payload.data)
  //     // console.log(response.data)
  //     if (response.data.success) {
  //         yield put(createUserSuccess(response.data))
  //         // window.location.reload();
  //     } else {
  //         yield put(createUserFailed(response.data.message));
  //     }
  // } catch (error) {
  //     // console.log(error.response);
  //     let message;
  //     if (error.response) {
  //         switch (error.response.status) {
  //             case 500:
  //                 message = 'Internal Server Error';
  //                 break;
  //             case 404:
  //                 message = 'Not found';
  //                 break;
  //             case 401:
  //                 message = 'Invalid credentials';
  //                 break;
  //             default:
  //                 message = error.response.data.message;
  //         }
  //     }
  //     else if (error.message) {
  //         message = error.message;
  //     }
  //     yield put(createUserFailed(message));
  // }
}

function* confirmUser(payload) {
  yield console.log(payload.payload.data);
  try {
    const response = yield axios.get(
      `profile/fetch-user-by-number?phoneNumber=${payload.payload.data.phoneNumber}`,
      payload.payload.data
    );
    // console.log(response.data)
    if (response.data.success) {
      console.log(response.data);
      yield put(confirmUserSuccess(response.data.data));
      // window.location.reload();
    } else {
      yield put(confirmUserFailed(response.data.message));
    }
  } catch (error) {
    // console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(confirmUserFailed(message));
  }
}

export function* watchGetUser() {
  yield takeEvery(GET_USER, getUser);
}

export function* watchGetSingleUser() {
  yield takeEvery(GET_SINGLE_USER, getSingleUser);
}

export function* watchGetUserProfile() {
  yield takeEvery(GET_USER_PROFILE, getUserProfile);
}

export function* watchEVPSaveSettings() {
  yield takeEvery(EVP_SETTINGS_REQUEST, saveEVPSettings);
}
export function* watchSEVPSaveSettings() {
  yield takeEvery(SEVP_SETTINGS_REQUEST, saveSEVPSettings);
}
export function* watchSkilledSaveSettings() {
  yield takeEvery(VENDOR_SETTINGS_REQUEST, saveSkilledSettings);
}
export function* watchCustomerSaveSettings() {
  yield takeEvery(CUSTOMER_SETTINGS_REQUEST, saveCustomerSettings);
}
export function* watchAdvertiserSaveSettings() {
  yield takeEvery(ADVERTISER_SETTINGS_REQUEST, saveAdvertiserSettings);
}
export function* watchAdvertsSaveSettings() {
  yield takeEvery(ADVERTS_SETTINGS_REQUEST, saveAdvertsSettings);
}
export function* watchGetSettings() {
  yield takeEvery(GET_SETTINGS_REQUEST, getSettings);
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_USER_REQUEST, createUser);
}
export function* watchVerifyUser() {
  yield takeEvery(VERIFY_USER_REQUEST, verifyUser);
}

export function* watchCreateAdvertiser() {
  yield takeEvery(CREATE_ADVERTISER, createAdvertiser);
}
export function* watchCreateCustomer() {
  yield takeEvery(CREATE_CUSTOMER, createCustomer);
}
export function* watchCreateSkilled() {
  yield takeEvery(CREATE_VENDOR, createSkilled);
}
export function* watchCreateEVP() {
  yield takeEvery(CREATE_EVP, createEVP);
}
export function* watchCreateSuperEVP() {
  yield takeEvery(CREATE_SUPEREVP, createSuperEVP);
}
export function* watchGetMyUsers() {
  yield takeEvery(GET_MY_USERS, getMyUsers);
}

export function* watchSuspendMyUser() {
  yield takeEvery(SUSPEND_MY_USER, suspendMyUser);
}

export function* watchUnsuspendMyUser() {
  yield takeEvery(UNSUSPEND_MY_USER, unSuspendMyUser);
}

export function* watchFundMyUser() {
  yield takeEvery(FUND_MY_USER, fundMyUser);
}

export function* watchWithdrawUserFund() {
  yield takeEvery(WITHDRAW_USER_FUND, withdrawUserFund);
}

export function* watchBlockUser() {
  yield takeEvery(BLOCK_USER, blockUser);
}

export function* watchUnblockUser() {
  yield takeEvery(UNBLOCK_USER, unblockUser);
}

export function* watchAddCredit() {
  yield takeEvery(ADD_CREDIT, addCredit);
}

export function* watchUpdateProfile() {
  yield takeEvery(UPDATE_USER_PROFILE, updateProfile);
}

export function* watchCreateUserCategory() {
  yield takeEvery(CREATE_USER_CATEGORY, createUserCategory);
}

export function* watchEditUserCategory() {
  yield takeEvery(EDIT_USER_CATEGORY, updateUserCategory);
}

export function* watchDeleteUserCategory() {
  yield takeEvery(DELETE_USER_CATEGORY, deleteUserCategory);
}

export function* watchGetUserCategory() {
  yield takeEvery(GET_USER_CATEGORY, getUserCategory);
}

export function* watchCreateUserSkill() {
  yield takeEvery(CREATE_USER_SKILL, createUserSkill);
}

export function* watchEditUserSkill() {
  yield takeEvery(EDIT_USER_SKILL, updateUserSkill);
}

export function* watchDeleteUserSkill() {
  yield takeEvery(DELETE_USER_SKILL, deleteUserSkill);
}

export function* watchEditUser() {
  yield takeEvery(EDIT_USER, editUser);
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

export function* watchConfirmUser() {
  yield takeEvery(CONFIRM_USER, confirmUser);
}

export function* watchUpdateProfilePic() {
  yield takeEvery(UPDATE_PROFILEPIC, updateProfilePic);
}

// export function* watchGetUserSkill() {
//     yield takeEvery(GET_USER_SKILL, getUserSkill);
// }

export default function* rootSaga() {
  yield all([
    fork(watchGetUser),
    fork(watchGetSingleUser),
    fork(watchGetUserProfile),
    fork(watchEVPSaveSettings),
    fork(watchSEVPSaveSettings),
    fork(watchSkilledSaveSettings),
    fork(watchAdvertsSaveSettings),
    fork(watchCustomerSaveSettings),
    fork(watchAdvertiserSaveSettings),
    fork(watchAdvertsSaveSettings),
    fork(watchGetSettings),
    fork(watchCreateUser),
    fork(watchVerifyUser),
    fork(watchCreateAdvertiser),
    fork(watchCreateCustomer),
    fork(watchCreateSkilled),
    fork(watchCreateEVP),
    fork(watchCreateSuperEVP),
    fork(watchGetMyUsers),
    fork(watchSuspendMyUser),
    fork(watchUnsuspendMyUser),
    fork(watchFundMyUser),
    fork(watchWithdrawUserFund),
    fork(watchBlockUser),
    fork(watchUnblockUser),
    fork(watchAddCredit),
    fork(watchUpdateProfile),
    fork(watchCreateUserCategory),
    fork(watchEditUserCategory),
    fork(watchDeleteUserCategory),
    fork(watchCreateUserSkill),
    fork(watchEditUserSkill),
    fork(watchDeleteUserSkill),
    fork(watchGetUserCategory),
    fork(watchEditUser),
    fork(watchDeleteUser),
    fork(watchConfirmUser),
    fork(watchUpdateProfilePic),
  ]);
}
