// eslint-disable-next-line import/no-cycle
import { all, fork, put, call, takeEvery } from 'redux-saga/effects';
import axios from 'helpers/axios';
import {
  CREATE_ADVERT,
  EDIT_ADVERT,
  DELETE_ADVERT,
  RENEW_ADVERT,
  GET_ADVERT,
  GET_FEATURE_ADVERT,
  CREATE_FEATURE_ADVERT,
  UPDATE_ADVERT_REVIEW,
  GET_USER_ADVERT,
  CREATE_ADVERT_CATEGORY,
  EDIT_ADVERT_CATEGORY,
  DELETE_ADVERT_CATEGORY,
  GET_ADVERT_CATEGORY,
  GET_SINGLE_ADVERT,
  // getUserProfile,
  createAdvertSuccess,
  createAdvertFailed,
  updateAdvertSuccess,
  updateAdvertFailed,
  deleteAdvertSuccess,
  deleteAdvertFailed,
  renewAdvertSuccess,
  renewAdvertFailed,
  getAdvertSucces,
  getAdvertFailed,
  updateAdvertReviewSucces,
  updateAdvertReviewFailed,
  createFeatureAdvertSuccess,
  createFeatureAdvertFailed,
  getFeatureAdvertSuccess,
  getFeatureAdvertFailed,
  getUserAdvertSucces,
  getUserAdvertFailed,
  createAdvertCategorySuccess,
  createAdvertCategoryFailed,
  updateAdvertCategorySuccess,
  updateAdvertCategoryFailed,
  deleteAdvertCategorySuccess,
  deleteAdvertCategoryFailed,
  getAdvertCategorySucces,
  getAdvertCategoryFailed,
  getSingleAdvertSucces,
  getSingleAdvertFailed,
} from '../actions';

function* getAdvert() {
  try {
    const response = yield axios.get(`/advert/all-adverts`);
    console.log(response.data);
    if (response.data.success) {
      yield put(getAdvertSucces(response.data));
    } else {
      yield put(getAdvertFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getAdvertFailed(message));
  }
}
function* getFeatureAdvert() {
  try {
    const response = yield axios.get(`/advert/all-featuredAds`);

    if (response.data.success) {
      yield put(getFeatureAdvertSuccess(response.data));
    } else {
      yield put(getFeatureAdvertFailed(response.data.message));
    }
  } catch (error) {
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getFeatureAdvertFailed(message));
  }
}

function* getUserAdvert() {
  try {
    const response = yield axios.get(`/advert/user-adverts`);
    console.log(response.data);
    if (response.data.success) {
      yield put(getUserAdvertSucces(response.data));
    } else {
      yield put(getUserAdvertFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getUserAdvertFailed(message));
  }
}

function* createAdvert(payload) {
  console.log(payload.payload.data)
  try {
    const response = yield axios.post('advert/create', payload.payload.data);
    if (response.data.success) {
      yield put(createAdvertSuccess(response.data.message));
      yield call(getAdvert);
      yield call(getUserAdvert);
      // yield call(getUserProfile)
    } else {
      yield put(createAdvertFailed(response.data.message));
    }
  } catch (error) {
    console.log(error);
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createAdvertFailed(message));
  }
}

function* updateAdvert(payload) {
  yield console.log(payload);
  const { id, message } = payload.payload;
  try {
    const response = yield axios.patch(`advert/update?advertId=${id}`, {
      message,
    });
    if (response.data.success) {
      yield put(updateAdvertSuccess(response.data.message));
      yield call(getUserAdvert);
    } else {
      yield put(updateAdvertFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let errMessage;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          errMessage = 'Internal Server Error';
          break;
        case 404:
          errMessage = 'Not found';
          break;
        case 401:
          errMessage = 'Invalid credentials';
          break;
        default:
          errMessage = error.response.data.message;
      }
    } else if (error.message) {
      errMessage = error.message;
    }
    yield put(updateAdvertFailed(errMessage));
  }
}

function* updateAdvertReview(payload) {
  try {
    const response = yield axios.put(
      `advert/review?advertId=${payload.payload?.id}&rating=${payload.payload?.rate}&review=${payload.payload?.message}`
    );
    console.log(response);
    if (response.data.success) {
      yield put(updateAdvertReviewSucces(response.data.message));
    } else {
      console.log(response.data.message);
      yield put(updateAdvertReviewFailed(response.data.message));
    }
  } catch (error) {
    let errMessage;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          errMessage = 'Internal Server Error';
          break;
        case 404:
          errMessage = 'Not found';
          break;
        case 401:
          errMessage = 'Invalid credentials';
          break;
        default:
          errMessage = error.response.data.message;
      }
    } else if (error.message) {
      errMessage = error.message;
    }
    yield put(updateAdvertReviewFailed(errMessage));
  }
}

function* deleteAdvert(payload) {
  try {
    const response = yield axios.delete(
      `advert/delete?advertId=${payload.payload.id}`
    );
    if (response.data.success) {
      yield put(deleteAdvertSuccess(response.data.message));
      yield call(getUserAdvert);
    } else {
      yield put(deleteAdvertFailed(response.data.message));
    }
  } catch (error) {
    console.log(error);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteAdvertFailed(message));
  }
}

function* createFeatureAdvert(payload) {
  try {
    const response = yield axios.post(
      `advert/feature-advert?advertId=${payload.payload.message.id}&userId=${payload.payload.message.userId}`,
      payload.payload.message.data
    );
    if (response.data.success) {
      yield put(createFeatureAdvertSuccess(response.data.message));
      yield call(getAdvert);
      yield call(getUserAdvert);
    } else {
      yield put(createFeatureAdvertFailed(response.data.message));
    }
  } catch (error) {
    console.log(error);
    let errMessage;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          errMessage = 'Internal Server Error';
          break;
        case 404:
          errMessage = 'Not found';
          break;
        case 401:
          errMessage = 'Invalid credentials';
          break;
        default:
          errMessage = error.response.data.message;
      }
    } else if (error.message) {
      errMessage = error.message;
    }
    yield put(createFeatureAdvertFailed(errMessage));
  }
}

function* renewAdvert(payload) {
  try {
    const response = yield axios.post(
      `advert/renew?advertId=${payload.payload.id}`
    );
    if (response.data.success) {
      yield put(renewAdvertSuccess(response.data.message));
      yield call(getUserAdvert);
    } else {
      yield put(renewAdvertFailed(response.data.message));
    }
  } catch (error) {
    console.log(error);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(renewAdvertFailed(message));
  }
}

function* getAdvertCategory() {
  try {
    const response = yield axios.get(`/advert/advert-categories`);
    console.log(response.data);
    if (response.data.success) {
      yield put(getAdvertCategorySucces(response.data));
    } else {
      yield put(getAdvertCategoryFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getAdvertCategoryFailed(message));
  }
}

function* createAdvertCategory(payload) {
  //    yield console.log(payload.payload.data)
  try {
    const response = yield axios.post(
      '/advert/advert-categories',
      payload.payload.data
    );
    if (response.data.success) {
      yield put(createAdvertCategorySuccess(response.data.message));
      yield call(getAdvert);
      yield call(getAdvertCategory);
      // yield call(getUserProfile)
    } else {
      yield put(createAdvertCategoryFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(createAdvertCategoryFailed(message));
  }
}

function* updateAdvertCategory(payload) {
  yield console.log(payload);
  const { id, message } = payload.payload;
  try {
    const response = yield axios.put(
      `/advert/advert-categories?categoryId=${id}`,
      { name: message }
    );
    if (response.data.success) {
      yield put(updateAdvertCategorySuccess(response.data.message));
      yield call(getAdvertCategory);
    } else {
      yield put(updateAdvertCategoryFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let errMessage;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          errMessage = 'Internal Server Error';
          break;
        case 404:
          errMessage = 'Not found';
          break;
        case 401:
          errMessage = 'Invalid credentials';
          break;
        default:
          errMessage = error.response.data.message;
      }
    } else if (error.message) {
      errMessage = error.message;
    }
    yield put(updateAdvertCategoryFailed(errMessage));
  }
}

function* deleteAdvertCategory(payload) {
  try {
    const response = yield axios.delete(
      `advert/delete?advertId=${payload.payload.id}`
    );
    if (response.data.success) {
      yield put(deleteAdvertCategorySuccess(response.data.message));
      yield call(getAdvertCategory);
    } else {
      yield put(deleteAdvertCategoryFailed(response.data.message));
    }
  } catch (error) {
    console.log(error);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(deleteAdvertCategoryFailed(message));
  }
}

function* getSingleAdvert(payload) {
  try {
    const response = yield axios.get(
      `/advert/single-advert?advertId=${payload.payload.id}`
    );
    console.log(response.data);
    if (response.data.success) {
      yield put(getSingleAdvertSucces(response.data));
    } else {
      yield put(getSingleAdvertFailed(response.data.message));
    }
  } catch (error) {
    console.log(error.response);
    let message;
    if (error.response) {
      switch (error.response.status) {
        case 500:
          message = 'Internal Server Error';
          break;
        case 404:
          message = 'Not found';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error.response.data.message;
      }
    } else if (error.message) {
      message = error.message;
    }
    yield put(getSingleAdvertFailed(message));
  }
}

export function* watchCreateAdvert() {
  yield takeEvery(CREATE_ADVERT, createAdvert);
}

export function* watchEditAdvert() {
  yield takeEvery(EDIT_ADVERT, updateAdvert);
}

export function* watchUpdateAdvertReview() {
  yield takeEvery(UPDATE_ADVERT_REVIEW, updateAdvertReview);
}

export function* watchDeleteAdvert() {
  yield takeEvery(DELETE_ADVERT, deleteAdvert);
}

export function* watchRenewAdvert() {
  yield takeEvery(RENEW_ADVERT, renewAdvert);
}

export function* watchCreateFeatureAdvert() {
  yield takeEvery(CREATE_FEATURE_ADVERT, createFeatureAdvert);
}

export function* watchGetAdvert() {
  yield takeEvery(GET_ADVERT, getAdvert);
}

export function* watchGetFeatureAdvert() {
  yield takeEvery(GET_FEATURE_ADVERT, getFeatureAdvert);
}

export function* watchGetUserAdvert() {
  yield takeEvery(GET_USER_ADVERT, getUserAdvert);
}

export function* watchCreateAdvertCategory() {
  yield takeEvery(CREATE_ADVERT_CATEGORY, createAdvertCategory);
}

export function* watchEditAdvertCategory() {
  yield takeEvery(EDIT_ADVERT_CATEGORY, updateAdvertCategory);
}

export function* watchDeleteAdvertCategory() {
  yield takeEvery(DELETE_ADVERT_CATEGORY, deleteAdvertCategory);
}

export function* watchGetAdvertCategory() {
  yield takeEvery(GET_ADVERT_CATEGORY, getAdvertCategory);
}

export function* watchGetSingleAdvert() {
  yield takeEvery(GET_SINGLE_ADVERT, getSingleAdvert);
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateAdvert),
    fork(watchEditAdvert),
    fork(watchDeleteAdvert),
    fork(watchRenewAdvert),
    fork(watchCreateFeatureAdvert),
    fork(watchGetAdvert),
    fork(watchUpdateAdvertReview),
    fork(watchGetFeatureAdvert),
    fork(watchGetUserAdvert),
    fork(watchCreateAdvertCategory),
    fork(watchEditAdvertCategory),
    fork(watchDeleteAdvertCategory),
    fork(watchGetAdvertCategory),
    fork(watchGetSingleAdvert),
  ]);
}
