// eslint-disable-next-line import/no-cycle
import {


    GET_USER,
    GET_USER_FAILED,
    GET_USER_SUCCESS,

    GET_USER_PROFILE,
    GET_USER_PROFILE_FAILED,
    GET_USER_PROFILE_SUCCESS,

    GET_SINGLE_USER,
    GET_SINGLE_USER_FAILED,
    GET_SINGLE_USER_SUCCESS,

    GET_SETTINGS_REQUEST,
    GET_SETTINGS_REQUEST_FAILED,
    GET_SETTINGS_REQUEST_SUCCESS,

    EVP_SETTINGS_REQUEST,
    EVP_SETTINGS_REQUEST_FAILED,
    EVP_SETTINGS_REQUEST_SUCCESS,

    SEVP_SETTINGS_REQUEST,
    SEVP_SETTINGS_REQUEST_FAILED,
    SEVP_SETTINGS_REQUEST_SUCCESS,

    VENDOR_SETTINGS_REQUEST,
    VENDOR_SETTINGS_REQUEST_FAILED,
    VENDOR_SETTINGS_REQUEST_SUCCESS,

    CUSTOMER_SETTINGS_REQUEST,
    CUSTOMER_SETTINGS_REQUEST_FAILED,
    CUSTOMER_SETTINGS_REQUEST_SUCCESS,

    ADVERTISER_SETTINGS_REQUEST,
    ADVERTISER_SETTINGS_REQUEST_FAILED,
    ADVERTISER_SETTINGS_REQUEST_SUCCESS,

    ADVERTS_SETTINGS_REQUEST,
    ADVERTS_SETTINGS_REQUEST_FAILED,
    ADVERTS_SETTINGS_REQUEST_SUCCESS,


    CREATE_USER_REQUEST,
    CREATE_USER_REQUEST_FAILED,
    CREATE_USER_REQUEST_SUCCESS,


    VERIFY_USER_REQUEST,
    VERIFY_USER_REQUEST_FAILED,
    VERIFY_USER_REQUEST_SUCCESS,

    CREATE_ADVERTISER,
    CREATE_ADVERTISER_FAILED,
    CREATE_ADVERTISER_SUCCESS,

    CREATE_CUSTOMER,
    CREATE_CUSTOMER_FAILED,
    CREATE_CUSTOMER_SUCCESS,

    CREATE_VENDOR,
    CREATE_VENDOR_FAILED,
    CREATE_VENDOR_SUCCESS,

    CREATE_EVP,
    CREATE_EVP_FAILED,
    CREATE_EVP_SUCCESS,

    CREATE_SUPEREVP,
    CREATE_SUPEREVP_FAILED,
    CREATE_SUPEREVP_SUCCESS,

    GET_MY_USERS,
    GET_MY_USERS_FAILED,
    GET_MY_USERS_SUCCESS,


    SUSPEND_MY_USER,
    SUSPEND_MY_USER_FAILED,
    SUSPEND_MY_USER_SUCCESS,

    UNSUSPEND_MY_USER,
    UNSUSPEND_MY_USER_FAILED,
    UNSUSPEND_MY_USER_SUCCESS,

    FUND_MY_USER,
    FUND_MY_USER_FAILED,
    FUND_MY_USER_SUCCESS,

    WITHDRAW_USER_FUND,
    WITHDRAW_USER_FUND_FAILED,
    WITHDRAW_USER_FUND_SUCCESS,

    BLOCK_USER,
    BLOCK_USER_FAILED,
    BLOCK_USER_SUCCESS,

    UNBLOCK_USER,
    UNBLOCK_USER_FAILED,
    UNBLOCK_USER_SUCCESS,

    ADD_CREDIT,
    ADD_CREDIT_FAILED,
    ADD_CREDIT_SUCCESS,

    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_FAILED,
    UPDATE_USER_PROFILE_SUCCESS,

    CREATE_USER_CATEGORY,
    CREATE_USER_CATEGORY_FAILED,
    CREATE_USER_CATEGORY_SUCCESS,

    EDIT_USER_CATEGORY,
    EDIT_USER_CATEGORY_FAILED,
    EDIT_USER_CATEGORY_SUCCESS,

    DELETE_USER_CATEGORY,
    DELETE_USER_CATEGORY_FAILED,
    DELETE_USER_CATEGORY_SUCCESS,


    GET_USER_CATEGORY,
    GET_USER_CATEGORY_FAILED,
    GET_USER_CATEGORY_SUCCESS,


    CREATE_USER_SKILL,
    CREATE_USER_SKILL_FAILED,
    CREATE_USER_SKILL_SUCCESS,

    EDIT_USER_SKILL,
    EDIT_USER_SKILL_FAILED,
    EDIT_USER_SKILL_SUCCESS,

    DELETE_USER_SKILL,
    DELETE_USER_SKILL_FAILED,
    DELETE_USER_SKILL_SUCCESS,

    EDIT_USER,
    EDIT_USER_FAILED,
    EDIT_USER_SUCCESS,


    DELETE_USER,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESS,

    CONFIRM_USER,
    CONFIRM_USER_FAILED,
    CONFIRM_USER_SUCCESS,

     UPDATE_PROFILEPIC,
    UPDATE_PROFILEPIC_FAILED,
    UPDATE_PROFILEPIC_SUCCESS,

    // GET_USER_TRANSACTION,
    // GET_USER_TRANSACTION_FAILED,
    // GET_USER_TRANSACTION_SUCCESS

} from '../actions';



export const getUser = (data) => ({
    type: GET_USER,
    payload: { data }
})

export const getUserSuccess = (message) => ({
    type: GET_USER_SUCCESS,
    payload: message
})

export const getUserFailed = (message) => ({
    type: GET_USER_FAILED,
    payload: message
})


export const getUserProfile = (data) => ({
    type: GET_USER_PROFILE,
    payload: { data }
})

export const getUserProfileSuccess = (message) => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload: message
})

export const getUserProfileFailed = (message) => ({
    type: GET_USER_PROFILE_FAILED,
    payload: message
})



export const getSingleUser = (id) => ({
    type: GET_SINGLE_USER,
    payload: { id }
})



export const getSingleUserSuccess = (message) => ({
    type: GET_SINGLE_USER_SUCCESS,
    payload: message
})

export const getSingleUserFailed = (message) => ({
    type: GET_SINGLE_USER_FAILED,
    payload: message
})


export const saveEVPSettings = (formData) => ({
    type: EVP_SETTINGS_REQUEST,
    payload: { formData }
})

export const saveEVPSettingsSuccess = (message) => ({
    type: EVP_SETTINGS_REQUEST_SUCCESS,
    payload: message
})

export const saveEVPSettingsFailed = (message) => ({
    type: EVP_SETTINGS_REQUEST_FAILED,
    payload: message
})


export const saveSEVPSettings = (formData) => ({
    type: SEVP_SETTINGS_REQUEST,
    payload: { formData }
})

export const saveSEVPSettingsSuccess = (message) => ({
    type: SEVP_SETTINGS_REQUEST_SUCCESS,
    payload: message
})

export const saveSEVPSettingsFailed = (message) => ({
    type: SEVP_SETTINGS_REQUEST_FAILED,
    payload: message
})



export const saveSkilledSettings = (formData) => ({
    type: VENDOR_SETTINGS_REQUEST,
    payload: { formData }
})

export const saveSkilledSettingsSuccess = (message) => ({
    type: VENDOR_SETTINGS_REQUEST_SUCCESS,
    payload: message
})

export const saveSkilledSettingsFailed = (message) => ({
    type: VENDOR_SETTINGS_REQUEST_FAILED,
    payload: message
})


export const saveCustomerSettings = (formData) => ({
    type: CUSTOMER_SETTINGS_REQUEST,
    payload: { formData }
})

export const saveCustomerSettingsSuccess = (message) => ({
    type: CUSTOMER_SETTINGS_REQUEST_SUCCESS,
    payload: message
})

export const saveCustomerSettingsFailed = (message) => ({
    type: CUSTOMER_SETTINGS_REQUEST_FAILED,
    payload: message
})


export const saveAdvertiserSettings = (formData) => ({
    type: ADVERTISER_SETTINGS_REQUEST,
    payload: { formData }
})

export const saveAdvertiserSettingsSuccess = (message) => ({
    type: ADVERTISER_SETTINGS_REQUEST_SUCCESS,
    payload: message
})

export const saveAdvertiserSettingsFailed = (message) => ({
    type: ADVERTISER_SETTINGS_REQUEST_FAILED,
    payload: message
})


export const saveAdvertsSettings = (formData) => ({
    type: ADVERTS_SETTINGS_REQUEST,
    payload: { formData }
})

export const saveAdvertsSettingsSuccess = (message) => ({
    type: ADVERTS_SETTINGS_REQUEST_SUCCESS,
    payload: message
})

export const saveAdvertsSettingsFailed = (message) => ({
    type: ADVERTS_SETTINGS_REQUEST_FAILED,
    payload: message
})




export const getSettings = () => ({
    type: GET_SETTINGS_REQUEST,
})

export const getSettingsSuccess = (message) => ({
    type: GET_SETTINGS_REQUEST_SUCCESS,
    payload: message
})

export const getSettingsFailed = (message) => ({
    type: GET_SETTINGS_REQUEST_FAILED,
    payload: message
})


export const createUser = (data) => ({
    type: CREATE_USER_REQUEST,
    payload: { data }
})

export const createUserSuccess = (data) => ({
    type: CREATE_USER_REQUEST_SUCCESS,
    payload: data
})

export const createUserFailed = (message) => ({
    type: CREATE_USER_REQUEST_FAILED,
    payload: message
})

export const verifyUser = (data) => ({
    type: VERIFY_USER_REQUEST,
    payload: { data }
})

export const verifyUserSuccess = (data) => ({
    type: VERIFY_USER_REQUEST_SUCCESS,
    payload: data
})

export const verifyUserFailed = (message) => ({
    type: VERIFY_USER_REQUEST_FAILED,
    payload: message
})


export const createAdvertiser = (data) => ({
    type: CREATE_ADVERTISER,
    payload: { data }
})

export const createAdvertiserSuccess = (message) => ({
    type: CREATE_ADVERTISER_SUCCESS,
    payload: message
})

export const createAdvertiserFailed = (message) => ({
    type: CREATE_ADVERTISER_FAILED,
    payload: message
})

export const createCustomer = (data) => ({
    type: CREATE_CUSTOMER,
    payload: { data }
})

export const createCustomerSuccess = (message) => ({
    type: CREATE_CUSTOMER_SUCCESS,
    payload: message
})

export const createCustomerFailed = (message) => ({
    type: CREATE_CUSTOMER_FAILED,
    payload: message
})

export const createSkilled = (data) => ({
    type: CREATE_VENDOR,
    payload: { data }
})

export const createSkilledSuccess = (message) => ({
    type: CREATE_VENDOR_SUCCESS,
    payload: message
})

export const createSkilledFailed = (message) => ({
    type: CREATE_VENDOR_FAILED,
    payload: message
})

export const createEVP = (data) => ({
    type: CREATE_EVP,
    payload: { data }
})

export const createEVPSuccess = (message) => ({
    type: CREATE_EVP_SUCCESS,
    payload: message
})

export const createEVPFailed = (message) => ({
    type: CREATE_EVP_FAILED,
    payload: message
})

export const createSuperEVP = (data) => ({
    type: CREATE_SUPEREVP,
    payload: { data }
})

export const createSuperEVPSuccess = (message) => ({
    type: CREATE_SUPEREVP_SUCCESS,
    payload: message
})

export const createSuperEVPFailed = (message) => ({
    type: CREATE_SUPEREVP_FAILED,
    payload: message
})

export const getMyUsers = () => ({
    type: GET_MY_USERS,
})

export const getMyUsersSuccess = (message) => ({
    type: GET_MY_USERS_SUCCESS,
    payload: message
})

export const getMyUsersFailed = (message) => ({
    type: GET_MY_USERS_FAILED,
    payload: message
})



export const suspendMyUser = (id) => ({
    type: SUSPEND_MY_USER,
    payload: id
})

export const suspendMyUserSuccess = (message) => ({
    type: SUSPEND_MY_USER_SUCCESS,
    payload: message
})

export const suspendMyUserFailed = (message) => ({
    type: SUSPEND_MY_USER_FAILED,
    payload: message
})


export const unSuspendMyUser = (id) => ({
    type: UNSUSPEND_MY_USER,
    payload: id
})

export const unSuspendMyUserSuccess = (message) => ({
    type: UNSUSPEND_MY_USER_SUCCESS,
    payload: message
})

export const unSuspendMyUserFailed = (message) => ({
    type: UNSUSPEND_MY_USER_FAILED,
    payload: message
})


export const fundMyUser = (formData) => ({
    type: FUND_MY_USER,
    payload: { formData }
})

export const fundMyUserSuccess = (message) => ({
    type: FUND_MY_USER_SUCCESS,
    payload: message
})

export const fundMyUserFailed = (message) => ({
    type: FUND_MY_USER_FAILED,
    payload: message
})

export const withdrawUserFund = (formData) => ({
    type: WITHDRAW_USER_FUND,
    payload: { formData }

})

export const withdrawUserFundSuccess = (message) => ({
    type: WITHDRAW_USER_FUND_SUCCESS,
    payload: message
})

export const withdrawUserFundFailed = (message) => ({
    type: WITHDRAW_USER_FUND_FAILED,
    payload: message
})

export const blockUser = (id) => ({
    type: BLOCK_USER,
    payload: id
})

export const blockUserSuccess = (message) => ({
    type: BLOCK_USER_SUCCESS,
    payload: message
})

export const blockUserFailed = (message) => ({
    type: BLOCK_USER_FAILED,
    payload: message
})


export const unblockUser = (id) => ({
    type: UNBLOCK_USER,
    payload: id
})

export const unblockUserSuccess = (message) => ({
    type: UNBLOCK_USER_SUCCESS,
    payload: message
})

export const unblockUserFailed = (message) => ({
    type: UNBLOCK_USER_FAILED,
    payload: message
})


export const addCredit = (formData) => ({
    type: ADD_CREDIT,
    payload: { formData }
})

export const addCreditSuccess = (message) => ({
    type: ADD_CREDIT_SUCCESS,
    payload: message
})

export const addCreditFailed = (message) => ({
    type: ADD_CREDIT_FAILED,
    payload: message
})

export const updateProfile = (formData) => ({
    type: UPDATE_USER_PROFILE,
    payload: { formData }
})

export const updateProfileSuccess = (message) => ({
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: message
})

export const updateProfileFailed = (message) => ({
    type: UPDATE_USER_PROFILE_FAILED,
    payload: message
})


export const updateProfilePic = (formData) => ({
    type: UPDATE_PROFILEPIC,
    payload: { formData }
})

export const updateProfilePicSuccess = (message) => ({
    type: UPDATE_PROFILEPIC_SUCCESS,
    payload: message
})

export const updateProfilePicFailed = (message) => ({
    type: UPDATE_PROFILEPIC_FAILED,
    payload: message
})




export const createUserCategory = (data) => ({
    type: CREATE_USER_CATEGORY,
    payload: { data }
})

export const createUserCategorySuccess = (message) => ({
    type: CREATE_USER_CATEGORY_SUCCESS,
    payload: message
})

export const createUserCategoryFailed = (message) => ({
    type: CREATE_USER_CATEGORY_FAILED,
    payload: message
})


export const updateUserCategory = (message, id) => ({
    type: EDIT_USER_CATEGORY,
    payload: { message, id }
})

export const updateUserCategorySuccess = (message) => ({
    type: EDIT_USER_CATEGORY_SUCCESS,
    payload: message
})

export const updateUserCategoryFailed = (message) => ({
    type: EDIT_USER_CATEGORY_FAILED,
    payload: message
})


export const deleteUserCategory = (id) => ({
    type: DELETE_USER_CATEGORY,
    payload: { id }
})

export const deleteUserCategorySuccess = (message) => ({
    type: DELETE_USER_CATEGORY_SUCCESS,
    payload: message
})

export const deleteUserCategoryFailed = (message) => ({
    type: DELETE_USER_CATEGORY_FAILED,
    payload: message
})

export const getUserCategory = () => ({
    type: GET_USER_CATEGORY,
    // payload: { id }
})

export const getUserCategorySucces = (data) => ({
    type: GET_USER_CATEGORY_SUCCESS,
    payload: data
})

export const getUserCategoryFailed = (message) => ({
    type: GET_USER_CATEGORY_FAILED,
    payload: message
})


export const createUserSkill = (data) => ({
    type: CREATE_USER_SKILL,
    payload: { data }
})

export const createUserSkillSuccess = (message) => ({
    type: CREATE_USER_SKILL_SUCCESS,
    payload: message
})

export const createUserSkillFailed = (message) => ({
    type: CREATE_USER_SKILL_FAILED,
    payload: message
})


export const updateUserSkill = (message, id) => ({
    type: EDIT_USER_SKILL,
    payload: { message, id }
})

export const updateUserSkillSuccess = (message) => ({
    type: EDIT_USER_SKILL_SUCCESS,
    payload: message
})

export const updateUserSkillFailed = (message) => ({
    type: EDIT_USER_SKILL_FAILED,
    payload: message
})


export const deleteUserSkill = (id) => ({
    type: DELETE_USER_SKILL,
    payload: { id }
})

export const deleteUserSkillSuccess = (message) => ({
    type: DELETE_USER_SKILL_SUCCESS,
    payload: message
})

export const deleteUserSkillFailed = (message) => ({
    type: DELETE_USER_SKILL_FAILED,
    payload: message
})


export const editUser = (data) => ({
    type: EDIT_USER,
    payload: { data }
})

export const editUserSuccess = (message) => ({
    type: EDIT_USER_SUCCESS,
    payload: message
})

export const editUserFailed = (message) => ({
    type: EDIT_USER_FAILED,
    payload: message
})


export const deleteUser = (data) => ({
    type: DELETE_USER,
    payload: { data }
})

export const deleteUserSuccess = (message) => ({
    type: DELETE_USER_SUCCESS,
    payload: message
})

export const deleteUserFailed = (message) => ({
    type: DELETE_USER_FAILED,
    payload: message
})

export const confirmUser = (data) => ({
    type: CONFIRM_USER,
    payload: { data }
})

export const confirmUserSuccess = (data) => ({
    type: CONFIRM_USER_SUCCESS,
    payload: {data}
})

export const confirmUserFailed = (message) => ({
    type: CONFIRM_USER_FAILED,
    payload: message
})