/* eslint-disable */
import axios from 'axios';
const BASE_URL = 'https://plugbay-api.onrender.com/api/v1';
//const BASE_URL = 'https://plugbay-api-v1.herokuapp.com/api/v1';
// const BASE_URL = 'https://emplug-api.herokuapp.com/api/v1';
//digital ocean production url
// const BASE_URL = 'https://api.plugbay.com/api/v1';

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
/* eslint-enable */
