// eslint-disable-next-line import/no-cycle
import {
  CREATE_ADVERT,
  CREATE_ADVERT_FAILED,
  CREATE_ADVERT_SUCCESS,
  EDIT_ADVERT,
  EDIT_ADVERT_FAILED,
  EDIT_ADVERT_SUCCESS,
  DELETE_ADVERT,
  DELETE_ADVERT_FAILED,
  DELETE_ADVERT_SUCCESS,
  RENEW_ADVERT,
  RENEW_ADVERT_FAILED,
  RENEW_ADVERT_SUCCESS,
  GET_ADVERT,
  GET_ADVERT_FAILED,
  GET_ADVERT_SUCCESS,
  UPDATE_ADVERT_REVIEW,
  UPDATE_ADVERT_REVIEW_FAILED,
  UPDATE_ADVERT_REVIEW_SUCCESS,
  GET_FEATURE_ADVERT,
  GET_FEATURE_ADVERT_FAILED,
  GET_FEATURE_ADVERT_SUCCESS,
  CREATE_FEATURE_ADVERT,
  CREATE_FEATURE_ADVERT_FAILED,
  CREATE_FEATURE_ADVERT_SUCCESS,
  CREATE_ADVERT_CATEGORY,
  CREATE_ADVERT_CATEGORY_FAILED,
  CREATE_ADVERT_CATEGORY_SUCCESS,
  EDIT_ADVERT_CATEGORY,
  EDIT_ADVERT_CATEGORY_FAILED,
  EDIT_ADVERT_CATEGORY_SUCCESS,
  DELETE_ADVERT_CATEGORY,
  DELETE_ADVERT_CATEGORY_FAILED,
  DELETE_ADVERT_CATEGORY_SUCCESS,
  GET_ADVERT_CATEGORY,
  GET_ADVERT_CATEGORY_FAILED,
  GET_ADVERT_CATEGORY_SUCCESS,
  GET_USER_ADVERT,
  GET_USER_ADVERT_FAILED,
  GET_USER_ADVERT_SUCCESS,
  GET_SINGLE_ADVERT,
  GET_SINGLE_ADVERT_FAILED,
  GET_SINGLE_ADVERT_SUCCESS,
} from '../actions';

export const createAdvert = (data) => ({
  type: CREATE_ADVERT,
  payload: { data },
});

export const createAdvertSuccess = (message) => ({
  type: CREATE_ADVERT_SUCCESS,
  payload: message,
});

export const createAdvertFailed = (message) => ({
  type: CREATE_ADVERT_FAILED,
  payload: message,
});

export const updateAdvert = (message, id) => ({
  type: EDIT_ADVERT,
  payload: { message, id },
});

export const updateAdvertSuccess = (message) => ({
  type: EDIT_ADVERT_SUCCESS,
  payload: message,
});

export const updateAdvertFailed = (message) => ({
  type: EDIT_ADVERT_FAILED,
  payload: message,
});

export const deleteAdvert = (id) => ({
  type: DELETE_ADVERT,
  payload: { id },
});

export const deleteAdvertSuccess = (message) => ({
  type: DELETE_ADVERT_SUCCESS,
  payload: message,
});

export const deleteAdvertFailed = (message) => ({
  type: DELETE_ADVERT_FAILED,
  payload: message,
});

export const renewAdvert = (id) => ({
  type: RENEW_ADVERT,
  payload: { id },
});

export const renewAdvertSuccess = (message) => ({
  type: RENEW_ADVERT_SUCCESS,
  payload: message,
});

export const renewAdvertFailed = (message) => ({
  type: RENEW_ADVERT_FAILED,
  payload: message,
});

export const createFeatureAdvert = (message) => ({
  type: CREATE_FEATURE_ADVERT,
  payload: { message},
});

export const createFeatureAdvertSuccess = (message) => ({
  type: CREATE_FEATURE_ADVERT_SUCCESS,
  payload: message,
});

export const createFeatureAdvertFailed = (message) => ({
  type: CREATE_FEATURE_ADVERT_FAILED,
  payload: message,
});

export const getAdvert = () => ({
  type: GET_ADVERT,
  // payload: { id }
});

export const getAdvertSucces = (data) => ({
  type: GET_ADVERT_SUCCESS,
  payload: data,
});

export const getAdvertFailed = (message) => ({
  type: GET_ADVERT_FAILED,
  payload: message,
});

export const getFeatureAdvert = () => ({
  type: GET_FEATURE_ADVERT,
  // payload: { id }
});

export const getFeatureAdvertSuccess = (data) => ({
  type: GET_FEATURE_ADVERT_SUCCESS,
  payload: data,
});

export const getFeatureAdvertFailed = (message) => ({
  type: GET_FEATURE_ADVERT_FAILED,
  payload: message,
});

export const getUserAdvert = () => ({
  type: GET_USER_ADVERT,
  // payload: { id }
});

export const getUserAdvertSucces = (data) => ({
  type: GET_USER_ADVERT_SUCCESS,
  payload: data,
});

export const getUserAdvertFailed = (message) => ({
  type: GET_USER_ADVERT_FAILED,
  payload: message,
});

export const createAdvertCategory = (data) => ({
  type: CREATE_ADVERT_CATEGORY,
  payload: { data },
});

export const createAdvertCategorySuccess = (message) => ({
  type: CREATE_ADVERT_CATEGORY_SUCCESS,
  payload: message,
});

export const createAdvertCategoryFailed = (message) => ({
  type: CREATE_ADVERT_CATEGORY_FAILED,
  payload: message,
});

export const updateAdvertCategory = (message, id) => ({
  type: EDIT_ADVERT_CATEGORY,
  payload: { message, id },
});

export const updateAdvertCategorySuccess = (message) => ({
  type: EDIT_ADVERT_CATEGORY_SUCCESS,
  payload: message,
});

export const updateAdvertCategoryFailed = (message) => ({
  type: EDIT_ADVERT_CATEGORY_FAILED,
  payload: message,
});

export const deleteAdvertCategory = (id) => ({
  type: DELETE_ADVERT_CATEGORY,
  payload: { id },
});

export const deleteAdvertCategorySuccess = (message) => ({
  type: DELETE_ADVERT_CATEGORY_SUCCESS,
  payload: message,
});

export const deleteAdvertCategoryFailed = (message) => ({
  type: DELETE_ADVERT_CATEGORY_FAILED,
  payload: message,
});

export const getAdvertCategory = () => ({
  type: GET_ADVERT_CATEGORY,
  // payload: { id }
});

export const getAdvertCategorySucces = (data) => ({
  type: GET_ADVERT_CATEGORY_SUCCESS,
  payload: data,
});

export const getAdvertCategoryFailed = (message) => ({
  type: GET_ADVERT_CATEGORY_FAILED,
  payload: message,
});

export const getSingleAdvert = (id) => ({
  type: GET_SINGLE_ADVERT,
  payload: { id },
});

export const getSingleAdvertSucces = (data) => ({
  type: GET_SINGLE_ADVERT_SUCCESS,
  payload: data,
});

export const getSingleAdvertFailed = (message) => ({
  type: GET_SINGLE_ADVERT_FAILED,
  payload: message,
});

export const updateAdvertReview = (message, rate, id) => ({
  type: UPDATE_ADVERT_REVIEW,
  payload: { message, rate, id },
});

export const updateAdvertReviewSucces = (message) => ({
  type: UPDATE_ADVERT_REVIEW_SUCCESS,
  payload: message,
});

export const updateAdvertReviewFailed = (message) => ({
  type: UPDATE_ADVERT_REVIEW_FAILED,
  payload: message,
});
